import type { ExtendedQueryResult } from '@aurora/shared-client/components/useQueryWithTracing';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { hasAllPropertiesDefined } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type {
  CommunitySearchPropertiesQuery,
  CommunitySearchPropertiesQueryVariables
} from '../../types/graphql-types';
import communitySearchPropertiesQuery from './CommunitySearchProperties.query.graphql';

/**
 * Gets search chat properties (read-only settings) for the current community.
 *
 * @param module The module
 * @author Nicolas Pascual
 */
export default function useCommunitySearchChatProperties(
  module: NodeModule | string
): ExtendedQueryResult<CommunitySearchPropertiesQuery, CommunitySearchPropertiesQueryVariables> & {
  enabled: boolean;
} {
  const { data, loading, error, ...queryResponse } = useQueryWithTracing<
    CommunitySearchPropertiesQuery,
    CommunitySearchPropertiesQueryVariables
  >(module, communitySearchPropertiesQuery, { ssr: false });

  const enabled =
    !loading && data
      ? hasAllPropertiesDefined(data.community.searchProperties.chatProperties)
      : false;

  return { ...queryResponse, data, loading, error, enabled };
}
