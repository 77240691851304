import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import React, {
  forwardRef,
  type ForwardRefExoticComponent,
  type PropsWithoutRef,
  type RefAttributes,
  useContext
} from 'react';
import type { FormSpec } from '@aurora/shared-client/components/form/types';
import FormBuilder from '@aurora/shared-client/helpers/form/FormBuilder/FormBuilder';
import type { UrlQuery } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { SearchResultsPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import { useClassNameMapper } from 'react-bootstrap';
import type { Form } from '@aurora/shared-generated/types/graphql-schema-types';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { I18n } from '@aurora/shared-types/texts';

type Props = {
  /**
   * Localization to use for form.
   */
  i18n: I18n<unknown, unknown>;
  /**
   * Form id
   */
  formId: string;
  /**
   * Form schema
   */
  formSchema: Form;
  /**
   * Classname(s) to use on the form element
   */
  formClassName?: string;
  /**
   * Classname(s) to use on the search input
   */
  inputGroupClassName?: string;
  /**
   * Whether the search is loading
   */
  loading?: boolean;
  /**
   * Whether to use the clear button in the search input.
   */
  useClearButton?: boolean;
  /**
   * Whether to use the close button in the search input.
   */
  useCloseButton?: boolean;
  /**
   * Callback when the clear button is used.
   */
  onClearSearch?: (event: React.TouchEvent | React.MouseEvent) => void;
  /**
   * Callback when the close button is used.
   */
  onCloseSearch?: (event: React.TouchEvent | React.MouseEvent) => void;
  /**
   * Whether to focus the search inout
   */
  focus?: boolean;
  /**
   * The query term to use in the form's default value.
   */
  queryTerm?: string;
  /**
   * Property to specify whether search is global or restricted to node.
   */
  isSearchGlobal?: boolean;
  /**
   * Callback for the form's submit action.
   * @param formData
   */
  onSubmit?: (formData: SearchData) => void;
  /**
   * Callback for the form inputs focus state.
   */
  onFocus?: (event: React.FocusEvent) => void;
  /**
   * Callback for the form inputs blur state.
   */
  onBlur?: (event: React.FocusEvent) => void;
};

/**
 * Form data for the inline search form
 */
interface SearchData {
  /**
   * The term used to search
   */
  search: string;
}

/**
 * The inline search form displays a search input intended to be used by components
 * that want to build an inline search experience. This component is not intended to be
 * used by the Search Results Page.
 *
 * @author Adam Ayres
 */
const InlineSearchForm: ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLFormElement>
> = forwardRef(function SpotlightSearchFormForward(
  {
    i18n,
    formId,
    formSchema,
    formClassName,
    loading,
    useClearButton,
    useCloseButton,
    onClearSearch,
    onCloseSearch,
    inputGroupClassName,
    focus,
    queryTerm,
    isSearchGlobal,
    onSubmit,
    onFocus,
    onBlur
  },
  ref
) {
  const cx = useClassNameMapper();
  const { contextNode } = useContext(AppContext);
  const { router } = useEndUserRoutes();
  const delay = 500;

  const formSpecs: FormSpec<SearchData> = new FormBuilder<SearchData>(
    formId,
    i18n,
    {
      schema: formSchema,
      cx
    },
    { formClassName }
  )
    .addSearchField({
      name: 'search',
      size: 'lg',
      isLoading: loading,
      useClearButton,
      useCloseButton,
      onClearSearch,
      onCloseSearch,
      inputGroupClassName,
      formGroupSpec: {
        className: 'lia-g-mb-0',
        label: false
      },
      focus,
      defaultValue: queryTerm,
      onKeyDown: (event, formMethods) => {
        async function handleEvent() {
          if (event.key === 'Enter') {
            const { search } = formMethods.getValues();
            const searchTerm = search.trim();
            if (searchTerm.length > 1) {
              let queryParams: Partial<UrlQuery<EndUserQueryParams>> = {
                [EndUserQueryParams.SEARCH_KEY]: searchTerm
              };
              if (!isSearchGlobal) {
                queryParams = {
                  ...queryParams,
                  [EndUserQueryParams.SEARCH_FILTER_BY_LOCATION]: contextNode.id
                };
              }
              await router.pushRoute<SearchResultsPageAndParams>(
                EndUserPages.SearchResultsPage,
                null,
                queryParams
              );
            }
          }
        }
        handleEvent();
      },
      onFocus,
      onBlur
    })
    .build();

  return (
    <InputEditForm<SearchData>
      formRef={ref}
      formSpec={formSpecs}
      onSubmit={onSubmit}
      submitOnChange={{
        watchFields: ['search'],
        waitTime: delay
      }}
    />
  );
});

export default InlineSearchForm;
